/*
This plugin reads the insurance groups and returns the individual participants.

USAGE:

$(".InsInfo").getInsuranceProviders({
	terms: locationTerms,						//The VitalSite object on the page or the results from the call to 'getVitalSiteObjectByPage'.
	facetName : 'Insurance Participation',		//What to search for in terms
	containerElement: "ul",						//The markup already on the page that contains the insurance groups. This will need to be removed.
	itemWrapperElement: "li"					//The new markup that will be added to the page
});

*/

(function($) {
	$.fn.getInsuranceProviders = function(options) {
		var target = this;
		var terms = [];
		var uniqueTerms = [];

		var options = $.extend({}, options, $.fn.getInsuranceProviders.defaults);
		$.fn.getInsuranceProviders.defaults = {
			containerElement: "ul",
			itemWrapperElement: "li",
			facetName : 'Insurance Participation'
		};

		if (!("terms" in options)) {
			options.terms = [];
		}

		//Run a webservice for each participant and find all of the insurance plans that are accepted
		$(options.terms).each(function (index, element) {
			if (element.FacetName == options.facetName) {
				$(element.Terms).each(function (i, el) {
					$.getTermById({
						termId: el.TermId
					}, function (data) {
						if(data.RelatedTerms[0]) {
							var relatedTerms = data.RelatedTerms;
							$(relatedTerms).each(function(index, element) {
								terms.push(element.Name);
								if ($.inArray(element.Name, uniqueTerms) == -1) {
									uniqueTerms.push(element.Name); //only store the unique items
								}
							});
							addToMarkup(options);
						}
					});
				});
			}
		});

		function addToMarkup(opts) {
			var container = $(opts.containerElement, target);
			$(opts.itemWrapperElement, container).remove(); //Remove the old markup

			uniqueTerms.sort(); //Sort the new items
			$.each(uniqueTerms, function(i, el) {
				var newEl = $('<' + opts.itemWrapperElement + ' />').html(el);
				$(container).append(newEl);
			});
		};
	};
}(jQuery));
